<template>
  <v-col
    cols="12"
    class="pt-0 text-center"
  >
    <v-list v-if="conditions && conditions.length > 0">
      <template v-for="(condition, index) in conditions">
        <v-list-item 
          :key="index"
        >
          <v-list-item-content>
            <v-card
              outlined
              style="border-color:black"
            >
              <v-row>
                <v-col
                  cols="4"
                  class="pb-0"
                >
                  <v-select 
                    :items="offerProperties"
                    v-model="condition.property"
                    item-text="label"
                    item-value="key"
                    outlined
                    :label="$t('property')"
                    class="pt-5 pl-4"
                    disable-updates
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0"
                >
                  <v-select 
                    :items="offerOperators"
                    item-text="label"
                    item-value="key"
                    v-model="condition.operator"
                    outlined
                    :label="$t('operator')"
                    class="pt-5"
                    disable-updates
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-text-field 
                    v-model="condition.floatValue"
                    outlined
                    :label="$t('floatValue')"
                    class="pt-5"
                    type="number"
                    disable-updates
                  />
                </v-col>
                <v-col
                  cols="1"
                  class="text-left"
                >
                  <v-btn
                    icon
                    class="mt-8"
                    @click="removeCondition(index)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-alert
      v-else
      type="info"
      outlined
    >
      {{ $t('hereYouCanDefineTheConditionsThatWillInvokeTheOffer') }}
    </v-alert>
    <v-btn
      outlined
      @click="addCondition"
    >
      {{ $t('addCondition') }}
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-col>
</template>

<script>
export default {
    name: 'OfferConditionsList', 
    props: {
        offerConditions: {
            type: Array, 
            default: () => []
        }
    }, 
    data() {
      return {
        offerProperties: [
          {key: 'totalPrice', label: 'Total Price'}
        ],
        offerOperators: [
          {key: 'equals', label: 'Equals'},
          {key: 'lessThan', label: 'Less Than'},
          {key: 'greaterThan', label: 'Greater Than'},
          {key: 'between', label: 'Between'}
        ]
      }
    }, 
    computed: {
      conditions: {
        get() {
          return this.offerConditions
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
        addCondition() {
          this.$emit('addCondition')
        },
        removeCondition(index) {
          this.$emit('removeCondition', index)
        }
    },
    watch: {
    //Always parseFloat the latest pushed condition floatValue if its string
     //Can't get watcher to work
     /*  offerConditions: {
        handler(value) {
          if(typeof value[value.length - 1] !== 'undefined') {
            if(typeof value[value.length - 1].floatValue === 'string') {
              conditions[value.length - 1].floatValue = parseFloat(value[value.length - 1].floatValue)
            }
          }
        }, 
        deep: true
      } */
    }
}
</script>
